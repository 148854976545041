import { useSelector } from "react-redux";
import { RootState } from "../../store";
import React, { useEffect, useRef, useState } from "react";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

import Errors, {
  extractArrayErrors,
  getFieldErrors,
} from "../../models/errors";
import {
  INVOICE_SALE_TYPE_CASH,
  INVOICE_SALE_TYPE_CASH_LABEL,
  INVOICE_SALE_TYPE_CREDIT,
  INVOICE_SALE_TYPE_CREDIT_LABEL,
  INVOICE_STATUS_APPROVED_BY_SET,
  INVOICE_STATUS_UPLOADED_TO_SET,
  Invoice,
  InvoiceItem,
  newInvoice,
  newInvoiceItem,
} from "../../models/invoice";
import Client from "../../models/client";
import { useHistory } from "react-router-dom";
import { errorAlert, successAlert } from "../utils/messages";
import Establishment from "../../models/establishment";
import {
  ItemRequestStatus,
  createItem,
  getItem,
  updateItem,
} from "../../api/generics";
import { NOT_FOUND, SUCCESS } from "../../utils/constants/tags";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputRadio,
  CLabel,
  CRow,
  CSelect,
  CTextarea,
} from "@coreui/react";
import EstablishmentSelect from "../establishments/EstablishmentSelect";
import { FieldErrors } from "../form/FieldErrors";
import ClientSelect from "../client/ClientSelect";
import Loading from "../indicators/Loading";
import ClientUpdateModal from "../client/ClientUpdateModal";
import Stamp from "../../models/stamp";
import StampSelect from "../stamps/StampSelect";
import { CURRENCY_CHOICES, PYG } from "../../currency/available-currencies";
import CurrencyField from "../currencies/CurrencyField";
import InvoiceItemForm from "./InvoiceItemForm";
import { Spinner } from "react-bootstrap";
import { getRoutePath } from "../../routes";
import DispatchPoint from "../../models/dispatch-point";
import DispatchPointSelect from "../dispatch-point/DispatchPointSelect";
import NotFound from "../indicators/NotFound";
import ServerError from "../indicators/ServerError";
import { getClientById } from "../../api/client";
import { getStampById } from "../../api/stamp";
import { getEstablishmentById } from "../../api/establishment";
import { getDispatchPointById } from "../../api/dispatch-point";
import { TRANSACTION_TYPE_CHOICES } from "../../models/transaction-type";
import PYGField from "../currencies/PYGField";
import { changeTZToUtc } from "../../utils/dates";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";
import { emptyValueOnUndefined } from "../../utils/fields";

const InvoiceForm = () => {
  const name = "Agregar Factura Electrónica";

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);

  const [loadError, setLoadError] = useState(false);
  const [loadNotFound, setLoadNotFound] = useState(false);

  const invoiceRef = useRef<Invoice | null>(newInvoice(taxPayer.id));
  const [invoiceDate, setInvoiceDate] = useState<Date | undefined>(undefined);

  const [saleType, setSaleType] = useState<string | undefined>(undefined);
  const [currency, setCurrency] = useState(PYG);
  const [pygExchangeRate, setPygExchangeRate] = useState<number | undefined>(
    undefined
  );
  const [totalAmount, setTotalAmount] = useState<number | undefined>(undefined);
  const [amount10Percent, setAmount10Percent] = useState<number | undefined>(
    undefined
  );
  const [amount5Percent, setAmount5Percent] = useState<number | undefined>(
    undefined
  );
  const [amountExempt, setAmountExempt] = useState<number | undefined>(
    undefined
  );
  const [iva10Percent, setIva10Percent] = useState<number | undefined>(
    undefined
  );
  const [iva5Percent, setIva5Percent] = useState<number | undefined>(undefined);

  const [applyDiscount, setApplyDiscount] = useState(false);
  const [globalDiscount, setGlobalDiscount] = useState<number | undefined>(
    undefined
  );

  /*const updateInvoiceRef = (invoice: Invoice) => {
    invoiceRef.current = invoice;
  };*/

  const [establishment, setEstablishment] = useState<Establishment | null>(
    null
  );

  const [dispatchPoint, setDispatchPoint] = useState<DispatchPoint | null>(
    null
  );

  const [stamp, setStamp] = useState<Stamp | null>(null);

  const [client, setClient] = useState<Client | null>(null);
  const [showAddClient, setShowAddClient] = useState(false);

  const [items, setItems] = useState<InvoiceItem[]>([]);

  const history = useHistory();

  const getEditingInvoiceId = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const editingInvoiceId = urlParams.get("id");

    if (editingInvoiceId !== null) {
      if (!isNaN(parseInt(editingInvoiceId))) {
        return parseInt(editingInvoiceId);
      }
    }

    return undefined;
  };

  const onEstablishmentChange = (newEstablishment: Establishment | null) => {
    if (invoiceRef.current === null) {
      return;
    }

    invoiceRef.current.establishmentId = newEstablishment?.id;
    invoiceRef.current.establishmentCode = newEstablishment?.code;

    setEstablishment(newEstablishment);
  };

  const onDispatchPointChange = (newDispatchPoint: DispatchPoint | null) => {
    if (invoiceRef.current === null) {
      return;
    }

    invoiceRef.current.dispatchPointId = newDispatchPoint?.id;
    invoiceRef.current.dispatchPointIdentifier = newDispatchPoint?.identifier;

    setDispatchPoint(newDispatchPoint);
  };

  const onStampChange = (newStamp: Stamp | null) => {
    if (invoiceRef.current === null) {
      return;
    }

    invoiceRef.current.stampId = newStamp?.id;
    invoiceRef.current.stampIdentifier = newStamp?.identifier;
    invoiceRef.current.stampBeginDate = newStamp?.beginDate;

    setStamp(newStamp);
  };

  const onClientChange = (newClient: Client | null) => {
    if (invoiceRef.current === null) {
      return;
    }

    invoiceRef.current.clientId = newClient?.id;
    invoiceRef.current.clientCode = newClient?.code;
    invoiceRef.current.clientIsTaxPayer = newClient?.isTaxPayer;
    invoiceRef.current.clientRuc = newClient?.ruc;
    invoiceRef.current.clientSocialName = newClient?.socialName;
    invoiceRef.current.clientFantasyName = newClient?.fantasyName;
    invoiceRef.current.clientType = newClient?.type;
    invoiceRef.current.clientCountryCode = newClient?.countryCode;
    invoiceRef.current.clientHasAddress = newClient?.hasAddress;
    invoiceRef.current.clientAddress = newClient?.address;
    invoiceRef.current.clientHouseNumber = newClient?.houseNumber;
    invoiceRef.current.clientDepartment = newClient?.department;
    invoiceRef.current.clientDepartmentDescription =
      newClient?.departmentDescription;
    invoiceRef.current.clientDistrict = newClient?.district;
    invoiceRef.current.clientDistrictDescription =
      newClient?.districtDescription;
    invoiceRef.current.clientCity = newClient?.city;
    invoiceRef.current.clientCityDescription = newClient?.cityDescription;
    invoiceRef.current.clientPhoneNumber = newClient?.phoneNumber;
    invoiceRef.current.clientEmail = newClient?.email;

    setClient(newClient);
  };

  const onInvoiceDateChange = (value: string | moment.Moment) => {
    if (invoiceRef.current === null) {
      return;
    }

    const newDate = value as moment.Moment;

    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    const invoiceDate = new Date((value as moment.Moment).toISOString());
    setInvoiceDate(invoiceDate);

    invoiceRef.current.invoiceDate = invoiceDate.toISOString();
  };

  const onSaleTypeChange = (newSaleType: string) => {
    if (invoiceRef.current === null) {
      return;
    }

    invoiceRef.current.saleType = newSaleType;
    setSaleType(newSaleType);
  };

  const onTransactionTypeCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (invoiceRef.current === null) {
      return;
    }

    if (!isNaN(parseInt(e.target.value))) {
      invoiceRef.current.transactionTypeCode = parseInt(e.target.value);
    } else {
      invoiceRef.current.transactionTypeCode = undefined;
    }
  };

  const onCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (invoiceRef.current === null) {
      return;
    }

    invoiceRef.current.currency = e.target.value;
    setCurrency(e.target.value);
  };

  const onPygExchangeRateChange = (newExchangeRate?: number) => {
    if (invoiceRef.current === null) {
      return;
    }

    invoiceRef.current.pygExchangeRate = newExchangeRate;
    setPygExchangeRate(newExchangeRate);
  };

  const onApplyDiscountChange = (applyDiscount: boolean) => {
    if (invoiceRef.current === null) {
      return;
    }

    setApplyDiscount(applyDiscount);
  };

  const onGlobalDiscountChange = (newGlobalDiscount?: number) => {
    if (invoiceRef.current === null) {
      return;
    }

    invoiceRef.current.globalDiscount = newGlobalDiscount;
  };

  const onTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (invoiceRef.current === null) {
      return;
    }

    invoiceRef.current.term = e.target.value;
  };

  const onObsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (invoiceRef.current === null) {
      return;
    }

    invoiceRef.current.obs = e.target.value;
  };

  const onAddClientClick = () => {
    setShowAddClient(true);
  };

  const onAddClientSuccess = (newClient?: Client) => {
    let message = "Cliente agregado correctamente";
    onClientChange(newClient ? newClient : null);
    if (newClient) {
      message = `Cliente ${newClient.socialName} agregado correctamente`;
    }
    successAlert(message);
    setShowAddClient(false);
  };

  const onAddClientCancel = () => {
    setShowAddClient(false);
  };

  const onSave = async () => {
    if (invoiceRef.current === null) {
      return;
    }

    setSubmitting(true);

    let requestPromise: Promise<ItemRequestStatus<Invoice>>;
    let successMessage = "Factura agregada exitósamente";

    const invoice = invoiceRef.current;
    invoice.taxPayerRuc = taxPayer.ruc;
    invoice.taxPayerSocialName = taxPayer.socialName;
    invoice.taxPayerFantasyName = taxPayer.fantasyName;

    if (invoice.currency === undefined) {
      invoice.currency = PYG;
    }

    if (invoice.id === undefined) {
      requestPromise = createItem<Invoice>("/invoices/create/", invoice);
    } else {
      requestPromise = updateItem<Invoice>(`/invoices/${invoice.id}/`, invoice);
    }

    const requestStatus = await requestPromise;

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";

      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      successAlert(successMessage);
      history.push(getRoutePath("invoices"));
    }
  };

  const loadInitialInvoiceById = async (): Promise<Invoice | null> => {
    const editingInvoiceId = getEditingInvoiceId();

    if (editingInvoiceId === undefined) {
      return null;
    }

    let requestStatus = await getItem<Invoice>(
      `/invoices/${editingInvoiceId}/`
    );

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.status === NOT_FOUND) {
        setLoadNotFound(true);
      } else {
        setLoadError(true);
      }

      return null;
    }

    if (requestStatus.data === undefined || requestStatus.data === null) {
      setLoadError(true);
      return null;
    }

    return requestStatus.data!;
  };

  const loadInitialData = async () => {
    setLoading(true);
    const invoice = await loadInitialInvoiceById();

    if (invoice === null) {
      setLoading(false);
      return;
    }

    if (
      invoice.status === INVOICE_STATUS_UPLOADED_TO_SET ||
      invoice.status === INVOICE_STATUS_APPROVED_BY_SET
    ) {
      setLoading(false);
      setLoadError(true);
    }

    const stampPromise = getStampById(invoice.stampId);
    const establishmentPromise = getEstablishmentById(invoice.establishmentId);
    const dispatchPointPromise = getDispatchPointById(invoice.dispatchPointId);
    const clientPromise = getClientById(invoice.clientId);

    const stamp = await stampPromise;
    const establishment = await establishmentPromise;
    const dispatchPoint = await dispatchPointPromise;
    const client = await clientPromise;

    invoiceRef.current = invoice;

    setInvoiceDate(new Date(invoice.invoiceDate!));

    setSaleType(invoiceRef.current.saleType!);

    setGlobalDiscount(invoice.globalDiscount);
    if (invoice.globalDiscount !== undefined) {
      setApplyDiscount(true);
    }
    setStamp(stamp);
    setEstablishment(establishment);
    setDispatchPoint(dispatchPoint);
    setClient(client);
    onItemsChange(invoice.items);

    setCurrency(invoice.currency ? invoice.currency : PYG);
    setPygExchangeRate(invoice.pygExchangeRate);

    setLoading(false);
  };

  useEffect(() => {
    const newInvoiceDate = changeTZToUtc(new Date());
    if (invoiceRef.current !== null) {
      invoiceRef.current.invoiceDate = newInvoiceDate.toISOString();
      setInvoiceDate(newInvoiceDate);
    }
    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddItemClick = () => {
    //const newItems = [...items, newInvoiceItem()];
    //onItemsChange(newItems);
    if (invoiceRef.current === null) {
      return;
    }

    const newItems = [...invoiceRef.current.items, newInvoiceItem()];
    onItemsChange(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onItemDelete = (index: number) => {
    if (invoiceRef.current === null) {
      return;
    }

    const newItems = [...invoiceRef.current.items];
    newItems.splice(index, 1);

    onItemsChange(newItems);
  };

  const onItemChange = (index: number, item: InvoiceItem) => {
    if (invoiceRef.current === null) {
      return;
    }

    const newItems = [...invoiceRef.current.items];
    newItems[index] = item;
    onItemsChange(newItems);
  };

  const onItemsChange = (newItems: InvoiceItem[]) => {
    if (invoiceRef.current === null) {
      return;
    }

    let newTotalAmount: number | undefined = undefined;
    let newAmount10Percent: number | undefined = undefined;
    let newAmount5Percent: number | undefined = undefined;
    let newAmountExempt: number | undefined = undefined;
    let newIva10Percent: number | undefined = undefined;
    let newIva5Percent: number | undefined = undefined;

    for (let item of newItems) {
      if (item.unitAmount !== undefined && item.quantity !== undefined) {
        newTotalAmount = newTotalAmount
          ? newTotalAmount + item.unitAmount * item.quantity
          : item.unitAmount * item.quantity;
      }

      if (item.amount10Percent !== undefined) {
        newAmount10Percent = newAmount10Percent
          ? newAmount10Percent + item.amount10Percent
          : item.amount10Percent;
      }

      if (item.amount5Percent !== undefined) {
        newAmount5Percent = newAmount5Percent
          ? newAmount5Percent + item.amount5Percent
          : item.amount5Percent;
      }

      if (item.amountExempt !== undefined) {
        newAmountExempt = newAmountExempt
          ? newAmountExempt + item.amountExempt
          : item.amountExempt;
      }

      if (item.iva10Percent !== undefined) {
        newIva10Percent = newIva10Percent
          ? newIva10Percent + item.iva10Percent
          : item.iva10Percent;
      }

      if (item.iva5Percent !== undefined) {
        newIva5Percent = newIva5Percent
          ? newIva5Percent + item.iva5Percent
          : item.iva5Percent;
      }
    }

    setTotalAmount(newTotalAmount);
    setAmount10Percent(newAmount10Percent);
    setAmount5Percent(newAmount5Percent);
    setAmountExempt(newAmountExempt);
    setIva10Percent(newIva10Percent);
    setIva5Percent(newIva5Percent);

    invoiceRef.current = {
      ...invoiceRef.current,
      items: newItems,
      amount: newTotalAmount,
      amount10Percent: newAmount10Percent,
      amount5Percent: newAmount5Percent,
      amountExempt: newAmountExempt,
      iva10Percent: newIva10Percent,
      iva5Percent: newIva5Percent,
    };

    setItems(newItems);
  };

  const itemErrors = extractArrayErrors("items", errors);

  if (loading) {
    return <Loading />;
  }

  if (loadNotFound) {
    return <NotFound />;
  }

  if (loadError) {
    return <ServerError />;
  }

  return (
    <>
      <CCard>
        <CCardHeader>{name}</CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Timbrado:
                </CLabel>
              </CCol>
              <CCol>
                <StampSelect onChange={onStampChange} value={stamp} />
                <FieldErrors
                  errors={getFieldErrors("stampId", errors) as string[]}
                ></FieldErrors>
                <FieldErrors
                  errors={getFieldErrors("stampIdentifier", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Establecimiento:
                </CLabel>
              </CCol>
              <CCol>
                <EstablishmentSelect
                  onChange={onEstablishmentChange}
                  value={establishment}
                />
                <FieldErrors
                  errors={getFieldErrors("establishmentId", errors) as string[]}
                ></FieldErrors>
                <FieldErrors
                  errors={
                    getFieldErrors("establishmentCode", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Punto de Expedición:
                </CLabel>
              </CCol>
              <CCol>
                <DispatchPointSelect
                  onChange={onDispatchPointChange}
                  value={dispatchPoint}
                />
                <FieldErrors
                  errors={getFieldErrors("dispatchPointId", errors) as string[]}
                ></FieldErrors>
                <FieldErrors
                  errors={
                    getFieldErrors(
                      "dispatchPointIdentifier",
                      errors
                    ) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Cliente:
                </CLabel>
              </CCol>
              <CCol>
                <CInputGroup>
                  <ClientSelect
                    value={client}
                    onChange={onClientChange}
                  ></ClientSelect>
                  <CInputGroupAppend>
                    <CButton
                      className="btn btn-primary"
                      // Prevent button on top
                      style={{ zIndex: 0 }}
                      onClick={onAddClientClick}
                    >
                      <i className="fa fa-plus"></i>
                    </CButton>
                  </CInputGroupAppend>
                </CInputGroup>
                <FieldErrors
                  errors={getFieldErrors("clientId", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Fecha de emisión:
                </CLabel>
              </CCol>
              <CCol>
                <Datetime
                  className="pl-0"
                  onChange={onInvoiceDateChange}
                  value={invoiceDate}
                  locale="es/PY"
                  utc
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  closeOnSelect={true}
                ></Datetime>
                <FieldErrors
                  errors={getFieldErrors("invoiceDate", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Condición de venta:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <div className="form-check form-check-inline">
                  <CInputRadio
                    id="saleTypeCash"
                    name="saleType"
                    className="form-check-input"
                    value={INVOICE_SALE_TYPE_CASH}
                    defaultChecked={
                      invoiceRef.current !== null &&
                      invoiceRef.current.saleType === INVOICE_SALE_TYPE_CASH
                    }
                    onChange={() => onSaleTypeChange(INVOICE_SALE_TYPE_CASH)}
                  ></CInputRadio>
                  <CLabel htmlFor="saleTypeCash" className="form-check-label">
                    {INVOICE_SALE_TYPE_CASH_LABEL}
                  </CLabel>
                </div>
              </CCol>
              <CCol md={2}>
                <div className="form-check form-check-inline">
                  <CInputRadio
                    id="saleTypeCredit"
                    name="saleType"
                    className="form-check-input"
                    value={INVOICE_SALE_TYPE_CREDIT}
                    defaultChecked={
                      invoiceRef.current !== null &&
                      invoiceRef.current.saleType === INVOICE_SALE_TYPE_CREDIT
                    }
                    onChange={() => onSaleTypeChange(INVOICE_SALE_TYPE_CREDIT)}
                  ></CInputRadio>
                  <CLabel htmlFor="saleTypeCredit" className="form-check-label">
                    {INVOICE_SALE_TYPE_CREDIT_LABEL}
                  </CLabel>
                </div>
              </CCol>
              <FieldErrors
                errors={getFieldErrors("saleType", errors) as string[]}
              ></FieldErrors>
              {saleType === INVOICE_SALE_TYPE_CREDIT ? (
                <>
                  <CCol md={2}>
                    <CLabel>Plazo:</CLabel>
                  </CCol>
                  <CCol>
                    <CInput
                      type="text"
                      defaultValue={
                        invoiceRef.current ? invoiceRef.current.term : ""
                      }
                      onChange={onTermChange}
                      placeholder="Plazo"
                    ></CInput>
                    <FieldErrors
                      errors={getFieldErrors("socialName", errors) as string[]}
                    ></FieldErrors>
                  </CCol>
                </>
              ) : (
                <></>
              )}
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Tipo de Transacción:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CSelect
                  type="text"
                  defaultValue={
                    invoiceRef.current
                      ? invoiceRef.current.transactionTypeCode
                      : undefined
                  }
                  onChange={onTransactionTypeCodeChange}
                >
                  <option value={""}>-----</option>
                  {Array.from(TRANSACTION_TYPE_CHOICES.entries()).map(
                    (entry) => {
                      return (
                        <option key={entry[0]} value={entry[0]}>
                          {entry[1]}
                        </option>
                      );
                    }
                  )}
                </CSelect>
                <FieldErrors
                  errors={
                    getFieldErrors("transactionTypeCode", errors) as string[]
                  }
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Moneda:</CLabel>
              </CCol>
              <CCol md={2}>
                <CSelect
                  type="text"
                  defaultValue={
                    invoiceRef.current !== null
                      ? invoiceRef.current.currency
                      : PYG
                  }
                  onChange={onCurrencyChange}
                >
                  <option value={""} disabled>
                    -----
                  </option>
                  {Array.from(CURRENCY_CHOICES.entries()).map((entry) => {
                    return (
                      <option key={entry[0]} value={entry[0]}>
                        {entry[1]}
                      </option>
                    );
                  })}
                </CSelect>
                <FieldErrors
                  errors={getFieldErrors("currency", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          {currency !== PYG ? (
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Cambio:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <PYGField
                    placeholder="Total"
                    limit={1000000000}
                    onChange={onPygExchangeRateChange}
                    value={pygExchangeRate}
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors("pygExchangeRate", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
          ) : (
            <></>
          )}

          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Obs:</CLabel>
              </CCol>
              <CCol md={10}>
                <CTextarea
                  placeholder="Info Adicional"
                  defaultValue={
                    invoiceRef.current !== null
                      ? invoiceRef.current.obs
                      : undefined
                  }
                  onChange={onObsChange}
                ></CTextarea>
                <FieldErrors
                  errors={getFieldErrors("obs", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>

          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Total:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="Total"
                  limit={1000000000}
                  disabled={true}
                  value={totalAmount}
                />
                <FieldErrors
                  errors={getFieldErrors("amount", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Total 10%:</CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="Total 10%"
                  limit={1000000000}
                  disabled={true}
                  value={amount10Percent}
                />
                <FieldErrors
                  errors={getFieldErrors("amount10Percent", errors) as string[]}
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>Total 5%:</CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="Total 5%"
                  limit={1000000000}
                  disabled={true}
                  value={amount5Percent}
                />
                <FieldErrors
                  errors={getFieldErrors("amount5Percent", errors) as string[]}
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>Total Exento:</CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="Total Exento"
                  limit={1000000000}
                  disabled={true}
                  value={amountExempt}
                />
                <FieldErrors
                  errors={getFieldErrors("amountExempt", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>IVA 10%:</CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="IVA 10%"
                  limit={1000000000}
                  disabled={true}
                  value={iva10Percent}
                />
                <FieldErrors
                  errors={getFieldErrors("iva10Percent", errors) as string[]}
                ></FieldErrors>
              </CCol>
              <CCol md={2}>
                <CLabel>IVA 5%:</CLabel>
              </CCol>
              <CCol md={2}>
                <CurrencyField
                  currency={currency}
                  placeholder="IVA 5%"
                  limit={1000000000}
                  disabled={true}
                  value={iva5Percent}
                />
                <FieldErrors
                  errors={getFieldErrors("iva5Percent", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Aplicar Descuento:</CLabel>
              </CCol>
              <CCol md={2}>
                <CRow>
                  <AnimatedCheckbox
                    value={
                      invoiceRef.current?.globalDiscount !== undefined &&
                      invoiceRef.current?.globalDiscount !== null
                    }
                    onChange={onApplyDiscountChange}
                  ></AnimatedCheckbox>
                </CRow>

                <FieldErrors
                  errors={getFieldErrors("applyDiscount", errors) as string[]}
                ></FieldErrors>
              </CCol>
              {applyDiscount ? (
                <>
                  <CCol md={2}>
                    <CLabel>Descuento Global:</CLabel>
                  </CCol>
                  <CCol md={2}>
                    <CurrencyField
                      currency={currency}
                      placeholder="Introduzca el monto"
                      onChange={onGlobalDiscountChange}
                      value={globalDiscount}
                      limit={1000000000}
                    />
                    <FieldErrors
                      errors={getFieldErrors("unitAmount", errors) as string[]}
                    ></FieldErrors>
                  </CCol>
                </>
              ) : (
                <></>
              )}
            </CRow>
          </CFormGroup>
          <CRow>
            <CCol>
              <h4>Ítems</h4>
            </CCol>
          </CRow>
          {items.map((item, ix) => (
            <CRow key={ix}>
              <CCol>
                <InvoiceItemForm
                  currency={currency}
                  item={item}
                  onDelete={() => onItemDelete(ix)}
                  onChange={(item) => onItemChange(ix, item)}
                  errors={ix < itemErrors.length ? itemErrors[ix] : {}}
                />
              </CCol>
            </CRow>
          ))}
          <CRow>
            <CCol>
              <CButton
                className="btn btn-primary float-right"
                onClick={onAddItemClick}
              >
                Agregar Ítem
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton type="submit" color="primary" onClick={onSave}>
            {submitting ? (
              <Spinner
                animation="grow"
                style={{
                  height: "17px",
                  width: "17px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
            ) : (
              <></>
            )}
            {submitting ? "Guardando..." : "Guardar"}
          </CButton>
        </CCardFooter>
      </CCard>
      <ClientUpdateModal
        show={showAddClient}
        onSuccess={onAddClientSuccess}
        onCancel={onAddClientCancel}
      />
    </>
  );
};

export default InvoiceForm;
