import {
  CButton,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSelect,
  CTextarea,
} from "@coreui/react";
import React, { useCallback, useEffect, useState } from "react";
import Errors, { errorsAreSame, getFieldErrors } from "../../models/errors";
import CurrencyField from "../currencies/CurrencyField";
import { FieldErrors } from "../form/FieldErrors";
import { emptyValueOnUndefined } from "../../utils/fields";
import {
  IVA_TYPE_10_PERCENT,
  IVA_TYPE_5_PERCENT,
  IVA_TYPE_CHOICES,
  IVA_TYPE_EXEMPT,
  IVA_TYPE_MIXED_10_PERCENT,
  IVA_TYPE_MIXED_5_PERCENT,
} from "../../models/iva";
import { CreditNoteItem } from "../../models/credit-note";

const addTotalAmount = (
  item: CreditNoteItem,
  unitAmount?: number,
  quantity?: number
): CreditNoteItem => {
  if (unitAmount !== undefined && quantity !== undefined) {
    item.totalAmount = unitAmount * quantity;
  }

  return item;
};

const addAmountValues = (item: CreditNoteItem) => {
  const newItem = { ...item };

  newItem.amount10Percent = undefined;
  newItem.amount5Percent = undefined;
  newItem.amountExempt = undefined;
  newItem.iva10Percent = undefined;
  newItem.iva5Percent = undefined;

  if (newItem.totalAmount !== undefined) {
    if (newItem.ivaType === IVA_TYPE_10_PERCENT) {
      newItem.amount10Percent = newItem.totalAmount;
      newItem.iva10Percent = Math.round(
        (newItem.totalAmount / (1 + 0.1)) * 0.1
      );
    }

    if (newItem.ivaType === IVA_TYPE_5_PERCENT) {
      newItem.amount5Percent = newItem.totalAmount;
      newItem.iva5Percent = Math.round(
        (newItem.totalAmount / (1 + 0.05)) * 0.05
      );
    }

    if (newItem.ivaType === IVA_TYPE_EXEMPT) {
      newItem.amountExempt = newItem.totalAmount;
    }
  }

  return newItem;
};

const addMissingValues = (item: CreditNoteItem) => {
  const newItem = { ...item };
  // TODO: see how to handle this
  newItem.measureUnit = 77;

  return newItem;
};

interface CreditNoteItemFormProps {
  item: CreditNoteItem;
  currency: string;
  onDelete: (_: CreditNoteItem) => void;
  onChange: (_: CreditNoteItem) => void;
  errors: Errors;
}

const CreditNoteItemForm: React.FC<CreditNoteItemFormProps> = ({
  item,
  currency,
  onDelete,
  onChange,
  errors,
}) => {
  const [editingItem, setEditingItem] = useState(item);
  const onDeleteClick = useCallback(() => {
    onDelete(editingItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingItem]);

  const onQuantityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newItem = { ...editingItem };
      if (!isNaN(parseInt(e.target.value))) {
        newItem.quantity = parseInt(e.target.value);
        newItem = addTotalAmount(
          newItem,
          newItem.unitAmount,
          parseInt(e.target.value)
        );
      } else {
        newItem.quantity = undefined;
        newItem = addTotalAmount(newItem, newItem.unitAmount, undefined);
      }

      newItem = addAmountValues(newItem);
      newItem = addMissingValues(newItem);

      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newItem = { ...editingItem };
      newItem.code = e.target.value;
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newItem = { ...editingItem };
      newItem.description = e.target.value;
      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onUnitAmountChange = useCallback(
    (newUnitAmount?: number) => {
      let newItem = { ...editingItem };
      newItem.unitAmount = newUnitAmount;
      newItem = addTotalAmount(newItem, newUnitAmount, newItem.quantity);
      newItem = addAmountValues(newItem);
      newItem = addMissingValues(newItem);

      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onIvaTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newItem = { ...editingItem };
      newItem.ivaType = e.target.value;

      if (
        newItem.ivaType === IVA_TYPE_10_PERCENT ||
        newItem.ivaType === IVA_TYPE_5_PERCENT
      ) {
        newItem.ivaBase = 100;
      } else if (newItem.ivaType === IVA_TYPE_EXEMPT) {
        newItem.ivaBase = 0;
      } else {
        newItem.ivaBase = undefined;
      }

      newItem = addAmountValues(newItem);
      newItem = addMissingValues(newItem);

      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  const onIvaBaseChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newItem = { ...editingItem };

      if (!isNaN(parseInt(e.target.value))) {
        newItem.ivaBase = parseInt(e.target.value);
      } else {
        newItem.ivaBase = undefined;
      }

      newItem = addAmountValues(newItem);
      newItem = addMissingValues(newItem);

      setEditingItem(newItem);
      onChange(newItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingItem]
  );

  useEffect(() => {
    setEditingItem(item);
  }, [item]);

  return (
    <div className="section border rounded mb-2 p-2">
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Cantidad:
            </CLabel>
          </CCol>

          <CCol md={1}>
            <CInput
              type="number"
              value={emptyValueOnUndefined(editingItem.quantity)}
              onChange={onQuantityChange}
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("quantity", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Cod. Ítem:
            </CLabel>
          </CCol>

          <CCol md={2}>
            <CInput
              type="text"
              value={emptyValueOnUndefined(editingItem.code)}
              onChange={onCodeChange}
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("code", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Detalle:
            </CLabel>
          </CCol>
          <CCol md={10}>
            <CTextarea
              placeholder="Detalle del ítem"
              value={emptyValueOnUndefined(editingItem.description)}
              onChange={onDescriptionChange}
            ></CTextarea>
            <FieldErrors
              errors={getFieldErrors("description", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Precio Unitario:
            </CLabel>
          </CCol>

          <CCol md={2}>
            <CurrencyField
              currency={currency}
              placeholder="Introduzca el monto"
              onChange={onUnitAmountChange}
              value={editingItem.unitAmount}
              limit={1000000000}
            />
            <FieldErrors
              errors={getFieldErrors("unitAmount", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CFormGroup>
        <CRow>
          <CCol md={2}>
            <CLabel>
              <span className="text-danger">*</span> Tipo IVA:
            </CLabel>
          </CCol>
          <CCol md={2}>
            <CSelect
              type="text"
              defaultValue={
                editingItem.ivaType ? editingItem.ivaType : IVA_TYPE_10_PERCENT
              }
              onChange={onIvaTypeChange}
            >
              <option value={""} disabled>
                -----
              </option>
              {Array.from(IVA_TYPE_CHOICES.entries()).map((entry) => {
                return (
                  <option key={entry[0]} value={entry[0]}>
                    {entry[1]}
                  </option>
                );
              })}
            </CSelect>
            <FieldErrors
              errors={getFieldErrors("ivaType", errors) as string[]}
            ></FieldErrors>
          </CCol>
          <CCol md={2}>
            <CLabel>Porcentaje IVA:</CLabel>
          </CCol>
          <CCol md={2}>
            <CInput
              type="number"
              defaultValue={emptyValueOnUndefined(editingItem.ivaBase)}
              onChange={onIvaBaseChange}
              placeholder="Porcentaje IVA"
              disabled={
                editingItem.ivaType !== IVA_TYPE_MIXED_10_PERCENT &&
                editingItem.ivaType !== IVA_TYPE_MIXED_5_PERCENT
              }
              value={emptyValueOnUndefined(editingItem.ivaBase)}
            ></CInput>
            <FieldErrors
              errors={getFieldErrors("ivaBase", errors) as string[]}
            ></FieldErrors>
          </CCol>
        </CRow>
      </CFormGroup>
      <CRow className="mt-2">
        <CCol>
          <CButton
            className="btn btn-danger float-right"
            onClick={onDeleteClick}
          >
            <i className="fa fa-trash"></i>
          </CButton>
        </CCol>
      </CRow>
    </div>
  );
};

const propsAreEqual = (
  prevItemProps: CreditNoteItemFormProps,
  nextItemProps: CreditNoteItemFormProps
): boolean => {
  return (
    prevItemProps.item.id === nextItemProps.item.id &&
    prevItemProps.item.obs === nextItemProps.item.obs &&
    prevItemProps.item.measureUnit === nextItemProps.item.measureUnit &&
    prevItemProps.item.unitAmount === nextItemProps.item.unitAmount &&
    prevItemProps.item.description === nextItemProps.item.description &&
    prevItemProps.item.quantity === nextItemProps.item.quantity &&
    prevItemProps.item.totalAmount === nextItemProps.item.totalAmount &&
    prevItemProps.item.amount10Percent === nextItemProps.item.amount10Percent &&
    prevItemProps.item.amount5Percent === nextItemProps.item.amount5Percent &&
    prevItemProps.item.amountExempt === nextItemProps.item.amountExempt &&
    prevItemProps.item.ivaType === nextItemProps.item.ivaType &&
    prevItemProps.item.ivaBase === nextItemProps.item.ivaBase &&
    prevItemProps.item.iva10Percent === nextItemProps.item.iva10Percent &&
    prevItemProps.item.iva5Percent === nextItemProps.item.iva5Percent &&
    errorsAreSame(prevItemProps.errors, nextItemProps.errors)
  );
};

export default React.memo(CreditNoteItemForm, propsAreEqual);
